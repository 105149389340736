import { AppBar, Grid, Toolbar } from '@mui/material'
import { IoInformationCircle } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { PAX8_ACCOUNT_STATUSES } from '../../frontendConsts'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

export const Pax8TrialBanner = () => {
  const history = useHistory()
  const { partner } = useSelector((state) => state.partner)
  const { company } = useSelector((state) => state.company)

  const [daysRemaining, setDaysRemaining] = useState(0)

  const redirectToPartnerBillingPage = () => {
    if (partner?.id) {
      history.push(`/partners/${partner.id}/billing`)
    } else if (company?.partnerId) {
      history.push(`/partners/${company.partnerId}/billing`)
    }
  }

  useEffect(() => {
    if (partner) {
      const currentDateTime = DateTime.now()

      if (partner.pax8TrialEndTimestamp) {
        const freeTrialEndDate = DateTime.fromSeconds(partner.pax8TrialEndTimestamp._seconds)
        setDaysRemaining(Math.ceil(freeTrialEndDate.diff(currentDateTime, 'days').toObject().days))
      }
    }
  }, [partner])

  return (
    <>
      {(partner && (
        partner.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.AWAITING_FREE_TRIAL_START ||
        partner.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIALING
      )) && (
        <AppBar sx={{ maxHeight: 48, backgroundColor: '#FF975C', color: '#000000', boxShadow: 'none', zIndex: 1201 }}>
          <Toolbar>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='flex-start'
              sx={{ flexGrow: 1, maxHeight: 48 }}
            >
              {partner && (
                <span className='phin-h6 margin-bottom:0'><IoInformationCircle size={24} />
                  <span>&nbsp;Hey Phriend! You have&nbsp;<b>{daysRemaining}&nbsp;days</b>&nbsp;left in your free trial.
                    {!partner.pax8PlanId && (' Select a plan to continue using this account after your trial is over.')}
                    &nbsp;Manage your subscription any time&nbsp;<u><a style={{ cursor: 'pointer' }} onClick={() => redirectToPartnerBillingPage()}>here</a></u>.
                  </span>
                </span>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </>
  )
}
