import { Badge, Chip, List } from '@mui/material'
import { IoCard, IoColorPalette, IoHome, IoSync } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { SideNavItem } from './SideNavItem'
import { PHIN_DISTRIBUTOR_ID } from '../../../frontendConsts'

function PartnerNavRoutes ({ id, isSideNavOpen }) {
  const { partner } = useSelector((state) => state.partner)
  const { authorization } = useSelector((state) => state.auth)
  const hasSeenCWBilling = authorization?.hasSeenCWBilling
  const isBillingAdmin = authorization.billingAdminPartnerIds.includes(id)

  if (authorization.blockFullPhinAccess) {
    return (
      <List>
        <SideNavItem
          id='partner-billing-nav-link'
          text='Billing'
          route={`/partners/${id}/billing`}
          isSideNavOpen={isSideNavOpen}
          icon={<IoCard style={{ color: 'var(--phin-white)' }} size='1.5em' />}
        />
      </List>
    )
  } else {
    return (
      <List>
        <SideNavItem
          id='partner-home-nav-link'
          text='Partner Home'
          route={`/partners/${id}`}
          isSideNavOpen={isSideNavOpen}
          icon={<IoHome style={{ color: 'var(--phin-white)' }} size='1.5em' />}
        />
        <SideNavItem
          id='partner-integrations-nav-link'
          text={!hasSeenCWBilling ? <>Integrations <Chip label='new' size='small' color='phinInvertedSecondary' sx={{ marginLeft: 'var(--phin-s-2)' }} /></> : 'Integrations'}
          route={`/partners/${id}/integrations`}
          isSideNavOpen={isSideNavOpen}
          icon={(!hasSeenCWBilling && !isSideNavOpen)
            ? <Badge color='phinInvertedSecondary' variant='dot'><IoSync style={{ color: 'var(--phin-white)' }} size='1.5em' /></Badge>
            : <IoSync style={{ color: 'var(--phin-white)' }} size='1.5em' />}
        />
        <SideNavItem
          id='partner-branding-nav-link'
          text='Branding'
          route={`/partners/${id}/branding`}
          isSideNavOpen={isSideNavOpen}
          icon={<IoColorPalette style={{ color: 'var(--phin-white)' }} size='1.5em' />}
        />
        {(partner && isBillingAdmin && (partner.distributorId === PHIN_DISTRIBUTOR_ID ||
          partner.distributorId === process.env.REACT_APP_PAX8_DISTRIBUTOR_ID)
        ) && (
          <SideNavItem
            id='partner-billing-nav-link'
            text='Billing'
            route={`/partners/${id}/billing`}
            isSideNavOpen={isSideNavOpen}
            icon={<IoCard style={{ color: 'var(--phin-white)' }} size='1.5em' />}
          />
        )}
      </List>
    )
  }
}

export default PartnerNavRoutes
