import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit'
import '../../css/integrationCard.css'
import Spinner from './../Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { integrationStatuses, integrationTypes } from '../../../frontendConsts.js'
import { disableSpamBypassThunk } from '../../../store/IntegrationsSlice'
import NotificationUtilities from '../notifications/notificationUtils'
import PhinModal from '../PhinModal.js'
import { Chip } from '@mui/material'

const integrationsCatalog = {
  userSync: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Microsoft User Sync',
    provider: 'Microsoft',
    integrationType: integrationTypes.USER_SYNC,
    id: integrationTypes.USER_SYNC,
    description: 'Available in Azure AD (global service) and ' +
    'Azure AD for US Government. Automatically sync users from a company\'s Microsoft Azure instance. ' +
    'You will be able to choose which users to continually sync updates for.',
    logo: '/azureSmall.png'
  },
  spamFilterBypass: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Graph API Mail Delivery',
    provider: 'Microsoft',
    integrationType: integrationTypes.SPAM_FILTER_BYPASS,
    id: integrationTypes.SPAM_FILTER_BYPASS,
    description: 'Use Microsoft Graph API to bypass Simple Mail Transfer Protocol (SMTP)',
    warning: 'Always use allowlisting. This API may be affected by the configurations in your tenant.',
    logo: '/azureSmall.png'
  },
  reportAPhish: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Report Phishing Button',
    provider: 'Microsoft',
    integrationType: integrationTypes.REPORT_A_PHISH,
    id: integrationTypes.REPORT_A_PHISH,
    description: 'Install Phin\'s phishing button add-in for Outlook. Pair this with phishing campaigns to get reported insights on user behavior and more.',
    logo: '/phin-x-azure-rap-icon.svg',
    tag: 'BETA'
  },
  googleUserSync: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Google User Sync',
    provider: 'Google',
    integrationType: integrationTypes.GOOGLE_USER_SYNC,
    id: integrationTypes.GOOGLE_USER_SYNC,
    description: 'Automatically sync users from a company\'s Google Workspace instance. ',
    logo: '/googleLogo.png',
    featureFlag: 'googleUserSync'
  },
  allowlist: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Allowlist',
    provider: 'Phin',
    integrationType: integrationTypes.ALLOWLIST,
    id: integrationTypes.ALLOWLIST,
    description: 'Automatically allowlist Phin IPs and domains to ensure they are not blocked by spam filters.',
    logo: '/logoBlueNoTextSmall.png',
    featureFlag: 'allowlist'
  }
}

export function IntegrationsPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { integrationsMap, loaders: integrationLoaders } = useSelector((state) => state.integrations)
  const { authorization } = useSelector((state) => state.auth)
  const hasSeenReportAPhish = authorization?.hasSeenReportAPhish
  const { isLoadingIntegrations } = integrationLoaders

  const [integrations, setIntegrations] = useState(integrationsCatalog)
  const [showDisconnectModal, setShowDisconnectModal] = useState(false)

  useEffect(() => {
    if (integrationsMap) {
      const newIntegrations = JSON.parse(JSON.stringify(integrations))
      for (const integration of Object.values(integrationsMap)) {
        const oldIntegration = integrations[integration.id]
        newIntegrations[integration.id] = Object.assign({}, oldIntegration, integration)
      }

      setIntegrations(newIntegrations)
    }
  }, [integrationsMap])

  const generateStatus = (integrationStatus, integrationId) => {
    if (integrationStatus === integrationStatuses.CONNECTED) {
      if (integrationId === integrationTypes.LEGACY_USER_SYNC || integrationId === integrationTypes.LEGACY_SPAM_BYPASS ||
        integrationId === integrationTypes.SPAM_FILTER_BYPASS) {
        return <p className='statusBadge connected-legacy'>connected</p>
      } else {
        return <p className='statusBadge connected'>connected</p>
      }
    } else if (integrationStatus === integrationStatuses.NOT_CONNECTED) {
      if (integrationId === integrationTypes.SPAM_FILTER_BYPASS) {
        return <p className='statusBadge deprecated'>connect</p>
      }
      return <p className='statusBadge connect'>connect</p>
    } else {
      return <p className='statusBadge connecting'>connecting</p>
    }
  }

  const disconnectLegacySpamBypass = async () => {
    const success = await dispatch(disableSpamBypassThunk({ id, history }))

    if (success) {
      toggleDisconnectModal()
    }
  }

  const toggleDisconnectModal = (integrationType) => {
    setShowDisconnectModal(!showDisconnectModal)
  }

  function generateOnClickFunction (integrationId) {
    if ((integrationId === integrationTypes.LEGACY_SPAM_BYPASS) ||
      (integrationId === integrationTypes.LEGACY_USER_SYNC)) {
      return () => toggleDisconnectModal(integrationId)
    } else {
      return () => history.push(`integrations/${integrationId}`)
    }
  }

  function generateIntegrationCard ({ key, integrationId, name, integrationStatus, integrationType, description, warning, logo, tag = null }) {
    const onclickFunction = generateOnClickFunction(integrationId)
    return (
      <div style={{ cursor: 'pointer' }} key={key} id={`integration-card-${integrationType}`} aria-label={`${integrationType} card`} onClick={onclickFunction}>
        <div className='interCardPadding'>
          <div className={(integrationId === integrationTypes.REPORT_A_PHISH && !hasSeenReportAPhish)
            ? 'betaBorder cardBody betaBorderAnimation'
            : 'cardBody'}
          >
            <div className='cardContents'>
              <div className='cardInfoQuick'>
                <img src={logo} className='cardLogo' />
                <p className='cardStatus'>{generateStatus(integrationStatus, integrationId)}</p>
              </div>
              <div className='cardTitle'>{name}
                {tag && <Chip size='small' label={tag} sx={{ backgroundColor: 'var(--purple-75)', color: 'white' }} />}
              </div>
              <p className='cardDesc'>{description}</p>
              {warning && <p className='cardDesc warning'><i>{warning}</i></p>}
            </div>
          </div>
        </div>
      </div>
    )
  }

  function generateIntegrationsList () {
    return Object.entries(integrations)
      .filter(([id, integration]) => !integration.featureFlag || (integration.featureFlag && company.featureFlags?.[integration.featureFlag]))
      .map(([id, integration], i) => {
        let { name, integrationStatus, description, warning } = integration

        if (integration.id === integrationTypes.LEGACY_USER_SYNC) {
          name = 'LEGACY User Sync'
          integrationStatus = integrationStatuses.CONNECTED
          description = 'This integration has limited support and will be decommissioned in early June. ' +
            'Please upgrade to the new version to continue getting the benefits of this integration.'
        } else if (integration.id === integrationTypes.LEGACY_SPAM_BYPASS) {
          name = 'LEGACY Spam Bypass'
          integrationStatus = integrationStatuses.CONNECTED
          description = 'This integration has limited support and will be decommissioned in early June. ' +
            'Please upgrade to the new version to continue getting the benefits of this integration.'
        }

        if (!name) {
          name = 'Unknown Integration'
          integrationStatus = integrationStatuses.CONNECTED
        }

        return (
          generateIntegrationCard({
            key: i,
            name,
            integrationId: integration.id,
            integrationStatus,
            integrationType: integration.integrationType,
            description,
            warning,
            logo: integration.logo,
            tag: integration.tag
          })
        )
      })
  }

  return (
    <div>
      <PhinModal
        isOpen={showDisconnectModal}
        title='Disconnect'
        close={toggleDisconnectModal}
        closeText='Cancel'
        action={disconnectLegacySpamBypass}
        actionText='Disconnect'
      >
        <p>Are you sure you want to disconnect this integraton? You can then begin use of the new integrations.</p>
      </PhinModal>

      <MDBRow className=''>
        <MDBCol className=''>
          <div className='phin-page-heading'>Microsoft Integrations</div>
        </MDBCol>
      </MDBRow>

      {(!company || !integrationsMap ||
      isLoadingCompany || isLoadingIntegrations) && (
        <Spinner />
      )}

      {(company && integrationsMap &&
      !isLoadingCompany && !isLoadingIntegrations) &&
        <MDBRow className='d-flex justify-content-start align-items-center'>
          {generateIntegrationsList()}
        </MDBRow>}
    </div>
  )
}
