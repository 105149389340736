import apiSlice from './apiSlice'

export const allowlistApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startAllowlist: builder.mutation({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/allowlist`,
        body: {},
        method: 'POST'
      })
    })
  })
})

export const {
  useStartAllowlistMutation
} = allowlistApi
